import _memoize from 'lodash/memoize';
import 'prop-types';
import 'invariant';
import { API_IDS_PATHNAMES } from '@dbh/api-constants';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { hash, Map } from 'immutable';
import { isCountryWithForcedEnglishLocale, COUNTRIES_WITH_FORCED_ENGLISH_LOCALE } from '@dbh/admindev-constants';
import rison from 'rison-node';
import 'react-immutable-proptypes';
import { memoize, toJS } from '@dbh/lodash-extra';
import _invert from 'lodash/invert';
import _isString from 'lodash/isString';
import { compile } from 'path-to-regexp';
import _noop from 'lodash/noop';
import 'lodash/endsWith';
import '@dbh/create-custom-react-prop-type';
import 'common-tags';
import '@dbh/generic-types';

/*
 *
 * Constants: `@dbh/make-api-url`.
 *
 */// We put `rison` encoded data in this query parameter.
// @see {@link https://github.com/Nanonid/rison}
const DBH_QUERY_PARAMETER_NAME="dbhq";// Must be identical to `BACK_END_API_CACHE_WORKER_PATHNAME` in
// `@dbh/cloudflare-back-end-api-cache-worker#constants.js`.
const BACK_END_API_CACHE_WORKER_PATHNAME="api/be-api-cache";

const forcedFrontEndApiDomainPropType=_noop;var forcedFrontEndApiDomainPropType$1 = forcedFrontEndApiDomainPropType;

/**
 * @typedef {Object} UrlOptions .
 * @property {Immutable.Map?} queryParameters .
 * @property {Immutable.Map?} routeParameters .
 * @property {boolean?} encodeQueryWithRison .
 * @property {string?} frontEndApiEnv .
 * @property {string?} forcedFrontEndApiDomain .
 */const urlOptionsPropType=_noop;var urlOptionsPropType$1 = urlOptionsPropType;

const urlWithoutQueryPropType=_noop;var urlWithoutQueryPropType$1 = urlWithoutQueryPropType;

const makeUrlWithQueryOptionsPropType=_noop;var makeUrlWithQueryOptionsPropType$1 = makeUrlWithQueryOptionsPropType;

/**
 * @typedef {Object} UrlOptions .
 * @property {Immutable.Map?} queryParameters .
 * @property {Immutable.Map} routeParameters .
 * @property {string} routeParameters.apiVersion .
 * @property {string} routeParameters.country .
 * @property {string} routeParameters.locale .
 * @property {(string|number)?} routeParameters.id .
 * @property {string?} routeParameters.ref .
 * @property {boolean?} encodeQueryWithRison .
 * @property {string} frontEndApiEnv .
 * @property {string?} forcedFrontEndApiDomain .
 */const makeApiUrlUrlOptionsPropType=_noop;var makeApiUrlUrlOptionsPropType$1 = makeApiUrlUrlOptionsPropType;

/**
 * @typedef {import('./types/types').UrlOptions} UrlOptions
 *//**
 * Custom `lodash` `memoize` resolver for `makeApiUrl`.
 * @param {string} API_ID .
 * @param {UrlOptions} urlOptions . @see `./validators`.
 * @param {boolean?} forceProductionApi Should it use `api.daybreakhotels.com`
 * instead of `apidev.daybreakhotels.com`.
 * @return {string} The hash.
 * @TODO `jest` test.
 */const makeApiUrlMemoizeResolver=withConformsTo("makeApiUrlMemoizeResolver",[],(a,b,c)=>{if(!!!b)return ""+a+c;const d=""+hash(b.queryParameters)+hash(b.routeParameters)+b.encodeQueryWithRison+b.frontEndApiEnv+b.forcedFrontEndApiDomain;return ""+a+c+d});var makeApiUrlMemoizeResolver$1 = makeApiUrlMemoizeResolver;

const apiIdToApiUrlPathname=a=>API_IDS_PATHNAMES[a],fnName="makeApiUrl",makeApiUrl=_memoize(withConformsTo(fnName,[],(a,b,c)=>{const{routeParameters:d,frontEndApiEnv:e,forcedFrontEndApiDomain:f}=b,g=apiIdToApiUrlPathname(a);let h;// We temporarily call the back end `API` directly on the main `test.` site
// and production sites, because there are some network callls timeouts
// issues. @TODO after migrating to `Google`, remove this and try again.
const i=["test","www"].includes(e);h=i&&!f?"www"===e||c?"api.daybreakhotels.com":"apidev.daybreakhotels.com":f?""+f:e+".daybreakhotels.com/"+BACK_END_API_CACHE_WORKER_PATHNAME;const j=d.get("id"),k=d.get("ref"),l=g.replace("(/:id)",j?"/"+j:"").replace("(/:ref)",k?"/"+k:""),m="https://"+h+"/"+d.get("apiVersion")+"/"+d.get("country")+"/"+d.get("locale")+"/"+l,{queryParameters:n}=b;if(!1===(null==n?void 0:n.isEmpty())){const a=new URL(m);n.forEach((b,c)=>a.searchParams.set(c,b));const{href:b}=a;return b}return m}),makeApiUrlMemoizeResolver$1);/**
 * Create a `DBH` back end `API` `URL`.
 * @param {string} API_ID .
 * @param {UrlOptions} urlOptions @see `./validators`.
 * @param {boolean?} forceProductionApi Should it use `api.daybreakhotels.com`
 * instead of `apidev.daybreakhotels.com`.
 * @return {string} The requested `API` `URL`.
 */var makeApiUrl$1 = makeApiUrl;

/**
 * Creates an `API` `URL` with extended configuration options, handling locale
 * overrides and environment-specific settings.
 * @param {string} API_ID The front end identifier of the `API`.
 * @param {Object?} urlOptionsParam The options used to build the `URL`.
 * @param {Object} urlOptionsParam.routeParameters .
 * @param {Object} urlOptionsParam.queryParameters .
 * @param {string} urlOptionsParam.locale .
 * @param {string} urlOptionsParam.country .
 * @param {string} urlOptionsParam.apiVersion .
 * @param {string[]} urlOptionsParam.frontEndApiEnv .
 * @param {string} urlOptionsParam.forcedFrontEndApiDomain .
 * @param {boolean} forceProductionApi Should we use `api.daybreakhotels.com`.
 * Instead of `apidev.daybreakhotels.com`.
 * @return {Function} .
 */const makeApiUrlWithOptions=withConformsTo("makeApiUrlWithOptions",[],(a,b,c)=>{const{routeParameters:d,queryParameters:e,locale:f,country:g,apiVersion:h,frontEndApiEnv:i,forcedFrontEndApiDomain:j}=b||{};let k=Map({locale:f,country:g,apiVersion:h}).mergeWith((a,b)=>b||a,d);const l=k.get("country");// Some countries have "forced" en-GB locale (like `AR`).
// But we want to use the "real" locale for their generated `API` `URLs`
// (@example `ar-AE` for `AE`), otherwise the `API` will either crash or
// return an empty result.
if(isCountryWithForcedEnglishLocale(l)){const a=COUNTRIES_WITH_FORCED_ENGLISH_LOCALE[l].realLocale;k=k.set("locale",a);}const[m]=i,n={queryParameters:e,routeParameters:k,encodeQueryWithRison:!1,frontEndApiEnv:c?"www":m,forcedFrontEndApiDomain:j};return makeApiUrl$1(a,n)});var makeApiUrlWithOptions$1 = makeApiUrlWithOptions;

/**
 * Returns a transliterate function that accepts a string and replaces all the
 * keys in the dictionary object with their values.
 * @example
 * ```js
 *  const transliterate = makeTransliterator({'&' : '-and-'});
 *  transliterate('B&B') -> 'B-and-B'
 * ```
 * @param {Object} dictionary The dictionary.
 * @return {Function} The transliterator.
 */const makeTransliterator=withConformsTo("makeTransliterator",[],a=>{const b="("+Object.keys(a).join("|")+")",c=new RegExp(b,"g");return b=>b.replace(c,b=>a[b])}),toCharCodes=a=>a.split("").map(a=>a.charCodeAt(0)).join("-"),toSafeString=a=>"__DBH_C_"+toCharCodes(a)+"__";// Be careful about changing this, URLs with the current special character may be
// "out there in the wild", starting from the tasks in the Trello board.
/**
 * Returns an object with two methods 'encode' and 'decode' that transliterate
 * a string parameter (un)escaping the characters in 'unsafeStrings'
 * with safe placeholders.
 * @param {string|string[]} unsafeStrings .
 * @return {{encode:Function, decode: Function}} .
 */const makeSafeEncoder=withConformsTo("makeSafeEncoder",[],a=>{const b=_isString(a)?a.split(""):a,c=b.reduce((a,b)=>{const c=a;return c[b]=toSafeString(b),c},{}),d=_invert(c);// Generate an object with the structure { '&': '__DBH_28__'}
// The decode dictionary is the inverse of the encode dictionary i.e. { __DBH_C_28__: '&'}
return {encode:makeTransliterator(c),decode:makeTransliterator(d)}});

const queryEncoder=makeSafeEncoder("&=[]");const encodeQueryParameter=queryEncoder.encode;const decodeQueryParameter=queryEncoder.decode;

const memoizedRisonUrlEncoder=memoize(a=>{if(a){const b=a.toJS(),c=rison.encode(b),d=encodeQueryParameter(c);// `rison` doesn't encode characters like & and =, we use our custom encoder.
return DBH_QUERY_PARAMETER_NAME+"="+d}return ""}),memoizedStringifyUrlEncoder=memoize(a=>{const b=a.toJS(),c=new URLSearchParams(b);return c.toString()}),makeLocationSearch=withConformsTo("makeLocationSearch",[],// Internal `URLs` should always use `rison`, so defaults to `true`.
(a,b)=>{const c="boolean"!=typeof b||b?memoizedRisonUrlEncoder:memoizedStringifyUrlEncoder,d=c(a);// Encode with `rison` or with `stringify`, according to options.
// The search part of location includes the question mark.
return "?"+d});/**
 * Given an `Immutable.Map` returns the location search.
 * @param {Immutable.Map} queryParameters Maps query keys to query values.
 * @param {boolean?} encodeQueryWithRison .
 * @return {string} A valid location search.
 * Note: a location search always starts with a `?`.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLHyperlinkElementUtils/search}
 * @throws If `queryParameters` is not an `Immutable.Map`.
 */var makeLocationSearch$1 = makeLocationSearch;

/**
 * Returns the given `URL`, expected not to have a query, with a query, built
 * according to the given options.
 * @param {string} urlWithoutQuery .
 * @param {Object} options .
 * @param {string?} options.hash .
 * @param {boolean?} options.encodeQueryWithRison .
 * @param {Immutable.Map?} options.queryParameters .
 * @return {string} The given `URL` with optional query string and hash appended
 * to it.
 */const makeUrlWithQuery=withConformsTo("makeUrlWithQuery",[],(a,b)=>{const{hash:c,queryParameters:d,encodeQueryWithRison:e}=b;if(!d)return c?""+a+c:a;// The search part of a location always starts with a `?`
const f=makeLocationSearch$1(d,e);return ""+a+f+(c||"")});var makeUrlWithQuery$1 = makeUrlWithQuery;

/*
 *
 * `convertPathToRegExpV1ToV2`: `@dbh/make-api-url`.
 *
 */// Converts a path (i.e /:country/:culture/something) from `path-to-regexp` version
// 0.1.x (used by `react-router` 3) to `path-to-regexp` version 2.x (used by
// `react-router` 4). `path-to-regexp` version 2.x has a very useful `compile` function
// to transform a `path-to-regexp` path (i.e /:country/:culture/something) to an URL,
// with a specified set of parameters (i.e { pageNumber: 3, someOptionalParameter: "test"})
// We need this function so that we can use `path-to-regexp` version 2.x `compile`
// function to build URLs from V1 paths converted to V2 paths by this function.
// @see {@link https://github.com/pillarjs/path-to-regexp/tree/v2.1.0#compile-reverse-path-to-regexp}
// @see {@link https://github.com/pillarjs/path-to-regexp}
const convertPathToRegExpV1ToV2=a=>{// Optional parameter converter.
// Example: we convert V1 path: /:country/:culture/something(/:someOptionalParameter)
// ...to V2 path: /:country/:culture/something/:someOptionalParameter?
// Add any other converters here.
return a.replace(/\((\/*:.+?)\)/g,"$1?")};var convertPathToRegExpV1ToV2$1 = convertPathToRegExpV1ToV2;

const escapeHttp=a=>a.replace(/(^https*):/,"$1\\:"),makeUrlFromPathToRegExpRoute=withConformsTo("makeUrlFromPathToRegExpRoute",[],(a,b)=>{const{queryParameters:c,routeParameters:d,encodeQueryWithRison:e}=b,f=convertPathToRegExpV1ToV2$1(a),g=escapeHttp(f),h=toJS(d);// We have to disable validation because otherwise we would have to revert
// to a very old version of `path-to-regexp` or adapt each parameter value
// inside `routeParameters` and `routePathToRegExpV2` itself, because
// slashes and other characters common in `URL` paths, are not accepted.
// @example
// https\://:subdomain.daybreakhotels.com/:apiVersion/:country/:locale/staticcontent/:id?
// toJS(routeParameters) = { ..., id: '/.well-known/no-content-path-old' }
// TypeError: Expected "id" to match "[^\/#\?]+?", but got "/.well-known/assetlinks.json"
// @see {@link https://github.com/pillarjs/path-to-regexp/issues/218}
let i=compile(g,{validate:!1})(h);if(c){i=makeUrlWithQuery$1(i,{encodeQueryWithRison:e,queryParameters:c});}return i});/**
 * Transforms a `path-to-regexp` `V2` path to a full `URL`, by replacing
 * the variable names (i.e :country) with the parameters in `urlOptions.routeParameters`
 * and optionally adding the query parameters in `urlOptions.queryParameters`.
 * `urlOptions` is required because at the very least one has to pass the
 * `urlOptions.routeParameters` Map inside it with `country` and `locale`.
 * @param {string} path @example `/:country/:culture/something` from
 * `https://example.com/:some/:variable/:name`.
 * @param {Object} urlOptions .
 * @return {string} The full `URL`, built from the given path and options.
 */var makeUrlFromPathToRegExpRoute$1 = makeUrlFromPathToRegExpRoute;

export { BACK_END_API_CACHE_WORKER_PATHNAME, DBH_QUERY_PARAMETER_NAME, convertPathToRegExpV1ToV2$1 as convertPathToRegExpV1ToV2, decodeQueryParameter, makeApiUrl$1 as default, encodeQueryParameter, forcedFrontEndApiDomainPropType$1 as forcedFrontEndApiDomainPropType, makeApiUrlUrlOptionsPropType$1 as makeApiUrlUrlOptionsPropType, makeApiUrlWithOptions$1 as makeApiUrlWithOptions, makeLocationSearch$1 as makeLocationSearch, makeUrlFromPathToRegExpRoute$1 as makeUrlFromPathToRegExpRoute, makeUrlWithQuery$1 as makeUrlWithQuery, makeUrlWithQueryOptionsPropType$1 as makeUrlWithQueryOptionsPropType, urlOptionsPropType$1 as urlOptionsPropType, urlWithoutQueryPropType$1 as urlWithoutQueryPropType };
